$breakpoint-tablet: 700px;

$sacramento: #022a1e;
$lime: #def54c;
$alt-lime: #bfdd00;
$sea-glass: #c2dedb;
$smoke: #f2f7f7;
$warning: #ff623e;

@font-face {
  font-family: "Twklausanne 400";
  src: url("/fonts/twklausanne.ttf") 
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// * {
//   font-family: "Twklausanne 400", sans-serif;
// }

@font-face {
  font-family: "Pe-icon-7-stroke";
  src: url("https://cdn-staging.securemobile.net/web/fonts/Pe-icon-7-stroke.eot?d7yf1v");
  src: url("https://cdn-staging.securemobile.net/web/fonts/Pe-icon-7-stroke.eot?#iefixd7yf1v")
      format("embedded-opentype"),
    url("https://cdn-staging.securemobile.net/web/fonts/Pe-icon-7-stroke.woff?d7yf1v")
      format("woff"),
    url("https://cdn-staging.securemobile.net/web/fonts/Pe-icon-7-stroke.ttf?d7yf1v")
      format("truetype"),
    url("https://cdn-staging.securemobile.net/web/fonts/Pe-icon-7-stroke.svg?d7yf1v#Pe-icon-7-stroke")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* MONTSERAT FONT */
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7p29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7g29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7r29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7q29NfpiOjk20.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUOjIg1_i6t8kCHKm459WxZqh7k29NfpiOj.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZBg_z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZYgzz_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm459WxRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8fZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z-PZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8_Zwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z8vZwjimrq1Q_.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUPjIg1_i6t8kCHKm459WxZOg3z_PZwjimrqw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxC7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRzS7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxi7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRxy7m0dR9pBOi.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUQjIg1_i6t8kCHKm45_QpRyS7m0dR9pA.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA3gnD_vx3rCs.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_cJD3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("https://cdn-staging.securemobile.net/web/fonts/montserrat-1.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("https://cdn-staging.securemobile.net/web/fonts/montserrat-3.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gTD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3g3D_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gbD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC3gfD_vx3rCubqg.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("https://cdn-staging.securemobile.net/web/fonts/montserrat-2.woff2")
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "FontAwesome";
  src: url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.eot?v=4.6.3");
  src: url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.eot?#iefix&v=4.6.3")
      format("embedded-opentype"),
    url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.woff2?v=4.6.3")
      format("woff2"),
    url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.woff?v=4.6.3")
      format("woff"),
    url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.ttf?v=4.6.3")
      format("truetype"),
    url("https://cdn-staging.securemobile.net/web/fonts/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: Montserrat, sans-serif !important;
  color: $sacramento;
}

body {
  margin: 0;
  padding: 0;
  // font-family: "Nunito", sans-serif;
  // font-weight: 200 !important;
  overflow-x: hidden;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

#root {
  background: white;
  float: left;
  width: 100vw;
  min-height: 100vh;
}

.line-break {
  height: 1px;
  width: 100%;
  background: $sacramento;
  margin-bottom: 10px;
  margin-top: 8px;
}

.clear {
  clear: both;
}

#back {
  cursor: pointer;
  margin-top: 15px;

  &:before {
    content: "\f060";
    position: relative;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    position: relative;
    top: 1px;
  }

  span {
    margin-left: 5px;
  }
}

#header {
  // background: #0198d8;
  background: $sacramento;
  color: white;
  height: 55px;

  #header-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;

    #toggle-nav-parent {
      float: left;
      width: 55px;
      height: 55px;
      // background: #0198d8;
      background: #022a1e;

      #toggle-nav {
        height: 23px;
        position: absolute;
        top: 16px;
        left: 14px;
        width: 30px;
      }

      // .nav-toggle {
      //   cursor: pointer;
      //   display: block;
      //   font-size: 0;
      //   left: 15px;
      //   position: absolute;
      //   top: 23px;
      //   z-index: 14;
      //   width: 26px;
      //   height: 18px;
      //   left: 11px !important;
      //   top: 18px !important;
      //   display: block !important;

      // &:before {
      //   position: absolute;
      //   left: 0;
      //   top: 8px;
      //   width: 16px;
      //   height: 2px;
      //   background: #fff;
      //   content: "";
      //   color: #fff;
      // }

      // span {
      //   &:before {
      //     position: absolute;
      //     left: 0;
      //     width: 26px;
      //     height: 2px;
      //     background: #fff;
      //     content: "";
      //     -moz-transition: all 0.5s ease;
      //     -webkit-transition: all 0.5s ease;
      //     -o-transition: all 0.5s ease;
      //     transition: all 0.5s ease;
      //     -moz-backface-visibility: hidden;
      //     -webkit-backface-visibility: hidden;
      //     backface-visibility: hidden;
      //     color: #fff;
      //   }

      //   &:after {
      //     position: absolute;
      //     left: 0;
      //     width: 26px;
      //     height: 2px;
      //     background: #fff;
      //     content: "";
      //     -moz-transition: all 0.5s ease;
      //     -webkit-transition: all 0.5s ease;
      //     -o-transition: all 0.5s ease;
      //     transition: all 0.5s ease;
      //     -moz-backface-visibility: hidden;
      //     -webkit-backface-visibility: hidden;
      //     backface-visibility: hidden;
      //     color: #fff;
      //     bottom: 0;
      //   }
      // }
      // }
    }

    #md-icon-parent {
      float: left;
      width: 55px;
      height: 55px;
      background: #022a1e;
      position: relative;

      img {
        width: 50px;
        position: absolute;
        top: 5px;
        left: 11px;
      }
    }

    .header-sm-image-parent {
      .header-sm-image {
        max-height: 62px;
        position: absolute;
        top: -2px;
        left: 50%;
        transform: translate(-50%, 0);
      }

      .header-registration-sm-image {
        max-height: 62px;
        position: absolute;
        top: -4px;
        right: 0px;
      }
    }

    #cart {
      position: absolute;
      top: 0;
      right: 0;
      width: 68px;
      height: 55px;

      img {
        width: 45px;
        position: relative;
        top: 11px;
      }

      // &:before {
      //   color: #fff;
      //   font-family: Pe-icon-7-stroke;
      //   content: "\e66e";
      //   font-size: 26px;
      // }

      #cart-quantity {
        position: absolute;
        top: 7px;
        right: 14px;
        background: #fff;
        color: $sacramento;
        font-size: 13px;
        padding: 2px 6px;
        border-radius: 20px;
      }
    }

    #header-registration-text {
      position: relative;
      top: 16px;
      left: 27px;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      width: 50%;
    }

    #header-checkout-text,
    #header-pages-text,
    #header-product-text {
      left: 50%;
      transform: translate(-50%);
      position: absolute;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      top: 15px;
      width: calc(100% - 155px);
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

#footer-terms-privacy {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
  clear: both;

  .footer-terms-privacy-item {
    a {
      text-decoration: none;
      font-size: 12px;
      color: gray;
    }
  }
}

#footer {
  float: left;
  text-align: center;
  width: 100%;
  display: block;

  #footer-powered-by-parent {
    margin-top: 10px;
    margin-bottom: 20px;

    img {
      height: 90px;
    }
  }
}

#supplied-by-footer {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 30px;

  #supplied-by-footer-parent {
    text-align: center;

    #supplied-by-img {
      width: 80px;
      margin-bottom: 20px;
    }

    #supplied-by-text {
      position: relative;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

#md-popup {
  position: fixed;
  top: 50%;
  z-index: -1;
  background: #fff;
  width: 340px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  font-size: 14px;
  transition: opacity 0.5s;
  opacity: 0;

  &.show-popup {
    opacity: 1;
    z-index: 1010;
  }

  #md-popup-x {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -1px;
    right: 0px;
    padding: 5px 10px;

    &:hover {
      background: #022a1e;
      color: white;
    }
  }

  #md-popup-content {
    margin-top: 5px;

    #md-popup-image-parent {
      text-align: center;
      margin-bottom: 12px;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

#supplied-by-popup {
  position: fixed;
  top: 50%;
  z-index: -1;
  background: #fff;
  width: 340px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  font-size: 14px;
  transition: opacity 0.5s;
  opacity: 0;
  max-height: 90vh;
  overflow-x: scroll;

  &.show-popup {
    opacity: 1;
    z-index: 1010;
  }

  #supplied-by-popup-x {
    font-size: 20px;
    font-weight: 700;
    position: absolute;
    top: -1px;
    right: 0px;
    padding: 5px 10px;

    &:hover {
      background: $alt-lime;
      color: $sacramento;
    }
  }

  #supplied-by-popup-content {
    margin-top: 5px;

    #supplied-by-popup-image-parent {
      text-align: center;
      margin-bottom: 12px;

      img {
        max-height: 70px;
        max-width: 100%;
      }
    }
  }
}

#mobile-popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1050;

  #mobile-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px;
    width: 90%;
    text-align: center;
    padding-bottom: 20px;
    border-radius: 4px;

    #mobile-popup-x {
      position: absolute;
      top: 5px;
      right: 8px;
      text-transform: capitalize;
      font-weight: 400;
      cursor: pointer;
    }

    .mobile-popup-heading {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 10px;
    }

    .input-box {
      margin-bottom: 10px;
    }

    button {
      background: $alt-lime;
      font-size: 14px;
      border: none;
      border-radius: 30px;
      height: 40px;
      font-weight: 400;
    }
  }
}

#new-sm-menu-mask {
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1009;
  transition: opacity 0.5s;

  &.show-mask {
    opacity: 1;
  }
}

#new-sm-menu {
  display: block !important;
  position: fixed;
  background: #fff;
  z-index: 1010;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: left 0.3s;
  width: 100%;
  height: 100%;
  overflow: auto;
  left: -100%;
  border-radius: 4px;

  #menu-menu-nav {
    height: 100%;
  }

  #account-nav {
    height: 100%;
  }

  a,
  a:visited {
    text-decoration: none;
    cursor: pointer;
    color: $sacramento;
  }

  #new-sm-menu-close,
  #new-sm-menu-close:visited {
    position: absolute;
    right: 0;
    font-size: 24px;
    padding-top: 3px;
    padding-bottom: 3px;
    width: 40px;
    text-align: center;
    color: $sacramento !important;
    text-decoration: none;
    cursor: pointer;

    &.hover {
      background: #022a1e;
      color: white;
    }
  }

  .menu-nav {
    width: calc(50% - 20px);
    float: left;
    text-align: center;
    font-size: 18px;
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;
    background: 0 0;
    color: $sacramento;

    &.sm-nav-active {
      background: $alt-lime;
      cursor: default;
    }
  }

  #new-sm-menu-buffer {
    width: 100%;
    height: 40px;
  }

  .new-sm-nav-item {
    font-size: 18px;
    position: relative;
    float: left;
    width: 100%;
    height: 20px;
    padding: 0px 15px;
    margin: 8px 0;

    .new-sm-nav-item-caret {
      float: left;
      width: 15%;
      position: relative;
      top: -10px;

      &:after {
        position: relative;
        left: 18px;
        font-family: FontAwesome;
        font-style: normal;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\f105";
        font-size: 24px;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -moz-backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        width: 40px;
        height: 40px;
        line-height: 40px;
      }
    }

    .new-sm-nav-item-text {
      float: left;
      width: 85%;
    }
  }

  .new-sm-nav-item-caret-back {
    float: left;
    top: 7px;
    left: 18px;
    width: 10%;

    &:after {
      position: relative;
      // left: 18px;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\f104";
      font-size: 24px;
      -moz-transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      width: 40px;
      height: 40px;
      line-height: 40px;
      top: 0;
      left: 18px;
    }
  }

  .categories-header {
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    float: left;
    width: 80%;
  }

  .category-back-icon {
    font-size: 32px !important;
    position: absolute;
    top: 4px;
    left: 16px;
    cursor: pointer;
  }
}

.container {
  #heading-registration {
    font-weight: 300;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #registration-payment-method-heading {
    color: $sacramento;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 8px;
  }

  #registration-header-top-section {
    float: left;
    width: 100%;
    position: relative;

    &.with-shop-image {
      // top: -12px;
    }

    #registration-top-left {
      width: calc(100% - 172px);
      float: left;
      position: relative;
      // top: 14px;
      // padding-right: 10px;

      #registration-shop-image-parent {
        margin-bottom: 4px;

        img {
          max-height: 80px;
          max-width: 100%;
          padding: 6px;
        }
      }
    }

    #registration-top-right {
      float: left;
      width: 172px;
      padding-left: 10px;
      position: relative;
      top: 10px;

      #registration-product-wrapper {
        height: 0;
        display: block;
        position: relative;
        z-index: 1;
        overflow: hidden;
        padding-bottom: 104%;
        margin-top: 3px;

        img {
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          height: auto;
          max-width: 100%;
          max-height: none;
        }
      }
    }
  }

  #registration-top-product {
    width: 25%;
    float: left;
    position: relative;

    #registration-top-product-wrapper {
      height: 0;
      display: block;
      position: relative;
      z-index: 1;
      overflow: hidden;
      padding-bottom: 78px;
      margin-top: 3px;

      img {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: auto;
        max-width: 100%;
        max-height: none;
        height: auto !important;
      }
    }
  }

  form#registration-form {
    margin-bottom: 65px;

    #registration-product-row {
      #registration-product-left {
        position: relative;
        float: left;
        width: calc(100% - 106px);
      }

      #registration-product-right {
        position: relative;
        float: left;
        width: 92px;
        margin-left: 10px;
        margin-right: 4px;

        #registration-product-wrapper {
          height: 0;
          display: block;
          position: relative;
          z-index: 1;
          overflow: hidden;
          padding-bottom: 92px;
          width: 92px;

          img {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            max-width: 100%;
            max-height: none;
            height: auto !important;
          }
        }
      }
    }
    input,
    select {
      border-radius: 31px;
      padding-left: 18px;
      font-size: 16px;
      margin-bottom: 3px;
      background: white;
    }

    .control {
      .group {
        .field {
          margin-bottom: 5px !important;
        }
        .stripe-card-element {
          border: 1px solid #c7c7c7;
          border-radius: 33px;
          padding-left: 16px;
        }
        #card-expiry-parent {
          float: left;
          width: 69%;
          padding-right: 1%;
        }
        #card-cvc-parent {
          float: left;
          width: 30%;
        }
      }
    }

    #registration-card-element {
      .StripeElement {
        padding: 10px;
        border-radius: 31px;
        width: 100%;
        border: 1px solid #c7c7c7;
        padding-left: 18px;
        font-size: 16px;
        // margin-bottom: 16px;
      }

      .input-box {
        margin-bottom: 0;
      }
    }

    #registration-button {
      width: 100%;
      height: 45px;
      border: 1px solid $sacramento;
      background-color: #fff;
      color: $sacramento;
      border-radius: 40px;
      margin-top: 15px;
      font-weight: 400;
      font-size: 16px;
      text-transform: uppercase;

      &:hover {
        border: 1px solid $alt-lime;
        background-color: $alt-lime;
      }
    }
  }

  #page-registration {
    #registration-display-container {
      background: $sacramento;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      transition: bottom 0.75s linear;
      z-index: 100;
      display: flex;
      justify-content: center;

      &.hide-registration-display {
        bottom: -500px;
      }

      #registration-display {
        padding: 0 10px;
        max-width: 400px;

        #display-image-parent {
          text-align: center;
          padding-top: 10px;
          margin-bottom: 15px;

          #display-image {
            color: white;
            font-size: 26px;
          }
        }
        #display-seller {
          color: $alt-lime;
          text-align: center;
          font-size: 22px;
          margin-bottom: 15px;
        }
        #display-desc-container {
          font-weight: bold;

          .display-desc-text {
            color: #fff;
            text-align: center;
            margin-bottom: 20px;
          }
        }
        #display-button-parent {
          text-align: center;
          margin-bottom: 15px;

          #display-button {
            background: #fff;
            color: $sacramento;
            border-radius: 40px;
            height: 45px;
            text-align: center;
            width: 80%;
            font-weight: 400;
          }
        }
        #display-footer {
          #display-footer-protected {
            color: #fff;
            text-align: center;
            margin-bottom: 5px;
          }
          #display-footer-image-parent {
            text-align: center;

            #display-footer-image {
              width: 110px;
            }
          }
        }
      }
    }

    #registration-display-show-more {
      background: $sacramento;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
      display: flex;
      justify-content: center;

      .show-more-arrow {
        display: flex;
        align-items: center;

        &:after {
          content: "\e682";
          font-family: Pe-icon-7-stroke;
          color: #fff;
          font-size: 40px;
        }
      }
    }
  }

  #page-home {
    #home-page-container {
      #home-main-section {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        // height: -webkit-fill-available;

        #home-black-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          // height: 100vh;
          // height: -webkit-fill-available;
          top: 0;
          left: 0;
          right: 0;
          background: rgba(0, 0, 0, 0.5);
          z-index: 10;
        }
        #home-video {
          position: absolute;
          z-index: 5;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        #home-content {
          z-index: 20;
          position: relative;
          height: 100%;
          // height: 100vh;
          // height: -webkit-fill-available;

          #home-top-content {
            position: absolute;
            top: 11%;
            left: 50%;
            transform: translate(-50%);
            text-align: center;

            #home-top-content-image {
              text-align: center;
              background: $sacramento;
              padding: 73px;
            }
          }

          #home-middle-content {
            position: absolute;
            top: 54%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            #home-middle-content-text {
              color: white;
              text-align: center;
              font-size: 35px;
            }
          }

          #home-bottom-content {
            position: absolute;
            top: 75%;
            left: 50%;
            transform: translate(-50%, 0);
            text-align: center;
            width: 90%;

            #home-bottom-content-text {
              color: white;
              font-size: 20px;
              margin-bottom: 15px;
            }
            #home-bottom-content-button {
              font-size: 20px;
              font-weight: bold;
              width: unset;
              color: white;
              background-color: $sacramento;
              border-radius: 0;
              border: 2px solid $sacramento;

              &:hover {
                background-color: white;
                color: $sacramento;
              }
            }
          }
        }

        #home-banner-content {
          padding-left: 15px;
          padding-right: 15px;

          #home-banner-content-container {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            width: 100%;
            max-width: 1400px;
            min-height: 650px;
            padding-right: 5%;
            padding-left: 5%;
            -webkit-box-pack: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 12px;
            background-color: rgba(194, 222, 219, 0.5);
            box-sizing: border-box;

            & > div {
              position: relative;
              display: -ms-grid;
              display: grid;
              max-width: 1400px;
              margin-right: auto;
              margin-left: auto;
              padding-top: 100px;
              padding-bottom: 100px;
              grid-auto-columns: 1fr;
              grid-column-gap: 32px;
              grid-row-gap: 32px;
              -ms-grid-columns: 1fr 1fr;
              grid-template-columns: 1fr 1fr;
              -ms-grid-rows: auto;
              grid-template-rows: auto;

              #home-banner-left {
                -ms-grid-column: span 1;
                grid-column-start: span 1;
                -ms-grid-column-span: 1;
                grid-column-end: span 1;
                -ms-grid-row: span 1;
                grid-row-start: span 1;
                -ms-grid-row-span: 1;
                grid-row-end: span 1;
                -ms-grid-row-align: center;
                align-self: center;
                position: relative;
                width: 100%;
                max-width: 550px;
                color: #022a1e;
                position: relative;

                #home-banner-left-content {
                  #home-banner-heading-1 {
                    font-size: 52px;
                    position: absolute;
                    top: -20px;
                    width: 900px;
                  }
                  #home-banner-heading-2 {
                    margin-top: 90px;
                    font-size: 48px;
                    line-height: 105%;
                    margin-bottom: 24px;
                  }
                  #home-banner-text {
                    font-size: 16px;
                    line-height: 130%;
                    margin-bottom: 10px;
                  }
                  #home-banner-button-parent {
                    margin-top: 40px;

                    #home-banner-button {
                      display: inline-block;
                      padding: 9px 15px;
                      background-color: #3898ec;
                      color: white;
                      border: 0;
                      line-height: inherit;
                      text-decoration: none;
                      cursor: pointer;
                      border-radius: 0;
                      padding: 12px 20px;
                      border-style: solid;
                      border-width: 0px;
                      border-color: #022a1e;
                      border-radius: 100px;
                      background-color: #022a1e;
                      -webkit-transition: all 350ms ease;
                      transition: all 350ms ease;
                      color: #def54c;
                      font-size: 16px;
                      width: unset;
                    }
                  }
                }
              }

              #home-banner-right {
                -ms-grid-column: span 1;
                grid-column-start: span 1;
                -ms-grid-column-span: 1;
                grid-column-end: span 1;
                -ms-grid-row: span 1;
                grid-row-start: span 1;
                -ms-grid-row-span: 1;
                grid-row-end: span 1;
                -ms-grid-row-align: center;
                align-self: center;
                -ms-grid-column-align: center;
                justify-self: center;
                position: relative;
                left: 0%;
                right: auto;
                bottom: auto;
                width: 100%;

                img {
                  max-width: 100%;
                  vertical-align: middle;
                  display: inline-block;
                }
              }
            }
          }
        }
      }

      #home-form-section {
        height: 100vh;
        position: relative;

        .container {
          max-width: unset;

          #home-form-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 550px;

            #form-title {
              font-size: 35px;
              margin-bottom: 5vh;
            }

            #form-form {
            }

            #form-request-demo {
              .text-field {
                width: 100%;
                height: 40px;
                margin-bottom: 16px;
                padding: 8px 12px;
                border-style: solid;
                border-width: 1px;
                border-color: rgba(0, 0, 0, 0.1);
                border-radius: 4px;
              }

              #form-request-demo-button {
                width: 100%;
                height: 48px;
                margin-top: 32px;
                border-radius: 100px;
                background-color: #022a1e;
                color: #def54c;
              }
            }
          }
        }
      }
    }
  }

  

  .shipping-address-right {
    float: left;
    width: 90%;

    #add-new-address-heading {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    #checkout-shipping-form {
      margin-top: 5px;

      input,
      select {
        border-radius: 31px;
        padding-left: 18px;
        font-size: 16px;
        margin-bottom: 3px;
        background: white;
      }

      #add-shipping-address-button {
        // width: 100%;
        // height: 45px;
        border: 1px solid $alt-lime;
        background-color: $alt-lime;
        border-radius: 40px;
        font-weight: 400;
        font-size: 15px;
        text-transform: uppercase;
        padding: 10px 20px;

        &:hover {
          color: $alt-lime;
          border: 1px solid $alt-lime;
          background-color: white;
        }
      }
    }
  }

  .add-shipping-address-container {
    width: 99%;
  }

  #page-checkout {
    #checkout-top {
      float: left;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 12px;
      height: 80px;

      #checkout-header-img {
        width: 50%;
        float: left;
        height: 75px;
        position: relative;

        img {
          max-height: 75px; 
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          max-width: 170px;
        }
      }
      #checkout-header-clock {
        width: 30%;
        float: right;
        font-size: 26px;
        text-align: center;
        margin-top: 15px;
        font-weight: 700;

        #checkout-header-confirm {
          font-size: 16px;
        }
        #clock-minutes {
        }
        #clock-seconds {
        }
      }
    }
    #checkout-cart {
      clear: both;

      .checkout-product {
        float: left;
        width: 100%;
        position: relative;
        margin-bottom: 10px;
        border: 1px solid $smoke;
        padding: 10px;
        border-radius: 7px;

        a {
          color: $sacramento;

          .checkout-left-col {
            float: left;
            width: 55%;

            & > div {
              margin-top: 2.5px;
              // margin-bottom: 2.5px;
              margin-bottom: 4px;
            }
          }
          .checkout-bold-left {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 0;
          }
          .checkout-normal {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 0;
          }
          .checkout-bold {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 0;
          }
          .checkout-qty {
            text-transform: uppercase;
            margin-top: 2px;
            margin-bottom: 6px;
            position: relative;

            .checkout-qty-number {
              border: 1px solid $sacramento;
              padding: 2px 7px;
              border-radius: 8px;
              font-size: 15px;
              font-weight: 700;
            }

            .checkout-qty-arrow:after {
              content: "\e688";
              font-family: Pe-icon-7-stroke;
              position: absolute;
              top: -4px;
              left: 8px;
              font-size: 28px;
              background: $alt-lime;
              border-radius: 27px;
            }

            .checkout-qty-arrow {
              position: relative;
              border-radius: 27px;
            }
          }

          .checkout-qty-control {
            margin-top: 6px;
            float: left;
            width: 100%;
            margin: 10px 0 !important;

            .qty-minus,
            .qty-plus {
              float: left;
              text-align: center;
              cursor: pointer;
            }

            .btn-qty {
              width: 30px;
              height: 30px;
              line-height: 30px;
              display: block;
              border: 1px solid $alt-lime;
              backface-visibility: hidden;
              text-decoration: none;
              border-radius: 34px;
              background: $alt-lime;

              &:hover {
                background: white;
                color: $alt-lime;
              }
            }

            .qty-input {
              float: left;

              #qty {
                border: none;
                background: 0 0;
                width: 38px;
                position: relative;
                left: 0;
                margin: 0 -1px;
                height: 30px;
                text-align: center;
              }
            }
          }

          .checkout-right-col {
            float: left;
            width: 45%;
            position: relative;

            .checkout-image {
              width: 134px;
              float: right;
              margin-bottom: 0;
              // position: absolute;
              // right: 0;
              // bottom: 0;
            }

            .checkout-giftcard-image {
              width: 150px;
              float: right;
              margin-bottom: 0;
              height: 93px;
              object-fit: cover;
              border-radius: 6px;
            }
          }
        }
      }

      #checkout-delivery-row,
        #checkout-total-row {
          position: relative;
          float: left;
          width: 100%;
          margin-bottom: 7px;

          .checkout-delivery-total {
            float: left;
            font-size: 16px;
            font-weight: 600;
          }
          .checkout-delivery-total-right {
            float: right;
            font-size: 16px;
            font-weight: 600;
          }
          #checkout-order-total {
            border-top: 1px solid $sacramento;
            border-bottom: 1px solid $sacramento;
          }
        }
    }

    #checkout-gift-cards {
      border-top: 2px solid #b1b1b1;
      margin-top: 4px;
      padding-top: 10px;
      position: relative;

      #checkout-gift-cards-heading {
        font-size: 16px;
        font-weight: 600;

        #checkout-gift-cards-arrow {
          position: relative;
          border-radius: 27px;

          &:after {
            content: "\e688";
            font-family: Pe-icon-7-stroke;
            position: absolute;
            top: -4px;
            left: 8px;
            font-size: 28px;
            background: $alt-lime;
            border-radius: 27px;
          }
        }
      }

      .product-gift-card-details-container {
        font-size: 14px;

        .gift-card-details-product-info {
          float: left;
          width: 100%;
          margin-bottom: 7px;
          border: 1px solid $smoke;
          padding: 10px;
          border-radius: 8px;
          position: relative;

          .gift-card-details-row {
            .gift-card-details-label {
              margin-bottom: 5px;
              font-weight: bold;
            }
            
            .gift-card-details-info {
              margin-bottom: 9px;
            }
          }
        }
      }
    }

    .checkout-arrow:after {
      content: "\e688";
      font-family: Pe-icon-7-stroke;
      position: absolute;
      top: -1px;
      right: -1px;
      font-size: 28px;
      color: #022a1e;
    }

    .checkout-arrow {
      position: absolute;
      top: 6px;
      right: 52px;
      background: $alt-lime;
      height: 26px;
      width: 26px;
      border-radius: 27px;
    }

    #checkout-shipping {
      border-top: 2px solid #b1b1b1;
      margin-top: 10px;
      padding-top: 10px;
      position: relative;
      float: left;
      width: 100%;

      #all-shipping-addresses {
        margin-top: 12px;
        padding-right: 10px;

        .shipping-address {
          font-weight: 300;
          font-size: 16px;
          float: left;
          width: 100%;
          margin-bottom: 8px;

          .shipping-address-left {
            float: left;
            height: 100%;
            width: 10%;

            .shipping-address-radio {
              position: relative;
              top: 10px;
            }

            .shipping-address-radio-new {
              position: relative;
              top: 5px;
            }
          }
        }
      }

      #checkout-shipping-heading {
        font-size: 16px;
        font-weight: 600;
      }

      #default-shipping-address {
        margin-top: 6px;
        margin-bottom: 6px;
        font-weight: 300;
        font-size: 16px;
      }

      .shipping-add-update {
        text-decoration: underline;
        font-size: 13px;
        font-weight: 400;
      }
    }

    #checkout-payment {
      border-top: 2px solid #b1b1b1;
      margin-top: 10px;
      padding-top: 10px;
      position: relative;
      float: left;
      width: 100%;

      #checkout-payment-heading {
        font-size: 16px;
        font-weight: 600;
      }

      .payment-add-update {
        text-decoration: underline;
        font-size: 13px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: 20px;
        cursor: pointer;
      }

      #payment-failed-message {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      #checkout-cards {
        float: left;
        width: 100%;

        .checkout-card {
          margin-top: 10px;
          margin-bottom: 15px;
        }

        .card-radio-parent {
          float: left;
          width: 50px;
          position: relative;
          height: 20px;

          .card-radio {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
          }

          #card-radio-new {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
          }
        }
        #card-new-card-heading {
          padding-left: 10px;
          float: left;

          #add-new-card-heading {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 10px;
          }
        }

        .card-number-parent {
          float: left;
          width: 100px;
          height: 20px;
          position: relative;

          .card-number {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 14px;
            font-weight: 400;
          }
        }

        .card-expiry-parent {
          .card-expiry {
            font-size: 14px;
            font-weight: 400;
          }
        }
      }

      #card-new-card-inputs {
        float: left;
        width: 100%;
        padding-left: 23px;
        padding-right: 5px;
        margin-bottom: 18px;

        .StripeElement {
          padding: 10px;
          border-radius: 31px;
          width: 100%;
          border: 1px solid #c7c7c7;
          padding-left: 18px;
          font-size: 16px;
          // margin-bottom: 16px;
        }

        .input-box {
          margin-bottom: 0;
        }
      }
    }

    #checkout-confirm-purchase {
      margin-top: 15px;
      position: relative;
      clear: both;

      button {
        border-radius: 35px !important;
        background: $alt-lime !important;
        border: none !important;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 10px 15px;
        width: 182px !important;
        font-weight: 400;
        height: 58px;
      }

      #checkout-lock-logo {
        position: absolute;
        top: 50%;
        right: 5%;
        height: 50px;
        transform: translate(0, -50%);
      }
    }

    #checkout-review-gift-card-details {
      margin-top: 15px;

      button {
        border-radius: 35px !important; 
        background: $alt-lime !important;
        border: none !important;
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 10px 15px;
        width: 240px !important;
        font-weight: 400;
        height: 58px;
      }
    }
  }

  #page-browse {
    .explanatory-text {
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  #page-sellerprofile {
    #seller-profile-header {
      position: relative;
      float: left;
      width: 100%;
      height: 100%;
      margin-bottom: 5px;

      #seller-profile-image {
        float: left;
        width: 38%;
        position: relative;
        padding-left: 10px;
        height: 80px;

        img {
          max-height: 100%;
          max-width: 100%;
          // border-radius: 64px;
          position: relative;
          left: 50%;
          -webkit-transform: translate(-50%);
          transform: translate(-50%, -50%);
          top: 50%;
        }
      }

      #seller-profile-desc {
        width: 60%;
        color: $sacramento;
        font-size: 17px;
        font-weight: 300;
        position: absolute;
        left: 42%;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  #page-success {
    text-align: center;
    font-size: 18px;

    #success-image-parent {
      padding-top: 60px;
      font-size: 48px;
      padding-top: 60px;
      margin-bottom: 1.5rem;

      #success-image {
        height: 100px;
      }
    }

    #thank-you-order {
      #thank-you-order-message,
      #thank-you-order-merchant-message {
        color: $sacramento;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      #thank-you-order-number {
        color: $sacramento;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: 6.46px;
      }

      #thank-you-order-merchant {
        color: $sacramento;
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 25px;
      }
    }
  }

  #page-expiredlink {
    #expired-link-content {
      text-align: center;

      img {
        margin-top: 60px;
      }

      #expired-link-heading {
        color: $sacramento;
        font-size: 25px;
        font-weight: 600;
        margin-top: 20px;
      }

      #expired-link-text-1,
      #expired-link-text-2 {
        color: $sacramento;
        font-size: 16px;
        font-weight: 600;
      }

      .expired-link-button-parent {
        text-align: center;

        .expired-link-button {
          border-radius: 37px;
          border: 2px solid $alt-lime;
          width: 228px;
          height: 45px;
          background-color: $alt-lime;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
        }

        #reconfirm-identity {
          margin-bottom: 10px;
        }

        #reconfirm-identity:hover,
        #expired-product-browse:hover {
          color: $alt-lime !important;
          background-color: #ffffff;
          border-color: $alt-lime !important;
        }
      }
    }
  }

  #page-product {
    #product-not-exists-message {
      text-align: center;
      margin: 30px 0;
      font-size: 20px;
    }
  }

  #product {
    margin-top: 20px;
    border: 1px solid #ffebeb;
    border-radius: 8px;
    padding: 10px;
  }

    #product-images {
      height: 300px;
      width: 100%;

      #product-main-image {
        height: 100%;
        width: 70%;
        float: left;
        padding: 5px;

        img {
          height: 100%;
          width: 100%;
          background-size: cover;
        }
      }
      #product-secondary-images {
        float: left;
        width: 27%;
        height: 100%;
        margin-right: 0px;
        padding-top: 5px;
        padding-bottom: 2px;

        .product-secondary-image-parent {
          width: 100%;
          height: 33%;
          padding-bottom: 5px;

          &:last-child {
            padding-bottom: 0;
          }

          .product-secondary-image {
            background-size: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    #giftcard-image {
      width: 100%;
      margin-bottom: 15px;

      #product-giftcard-image {
        height: 100%;
        width: 100%;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }

    #product-details {
      padding: 0 8px;
      margin-bottom: 17px;
      float: left;
      width: 100%;

      #product-details-text {
        position: relative;
        float: left;
        width: calc(100% - 120px);
        height: 100%;

        #product-name {
          clear: both;
          font-size: 18px;
          margin-top: 10px;
          margin-bottom: 5px;
          font-weight: 700;
        }

        #product-sku-stock {
          margin-bottom: 5px;

          #product-sku {
            text-transform: uppercase;
            color: grey;
            font-weight: 700;
            font-size: 10px;
          }

          #product-in-stock {
            margin-left: 10px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            color: $alt-lime;
          }

          #product-out-of-stock {
            margin-left: 10px;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 11px;
            color: $sea-glass;
          }
        }

        #product-price {
          font-size: 20px;
          font-weight: 600;
        }
      }

      #product-details-image {
        margin-top: 12px;
        position: relative;
        float: left;
        width: 120px;
        height: 80px;

        img {
          position: absolute;
          top: 50%;
          transform: translate(50%, -50%);
          right: 50%;
          max-width: 120px;
          max-height: 80px;
        }
      }
    }

    #product-configurable-options {
      float: left;
      width: 100%;
      margin-bottom: 25px;
      padding: 0px 6px;

      .swatch-attribute {
        width: 50%;
        float: left;

        .swatch-attribute-label {
          font-size: 12px;
        }
        .swatch-attribute-selected-option {
          margin-left: 6px;
          font-size: 12px;
        }
        .swatch-attribute-options {
          float: left;
          width: 100%;
          margin-top: 7px;

          .swatch-option {
            height: 37px;
            width: 37px;
            float: left;
            position: relative;
            border-radius: 50px;
            border: 2px solid #dbdbdb;
            margin-right: 5px;
            text-align: center;
            line-height: 35px;
            font-size: 13px;

            &.selected {
              border: 2px solid #0298d8;
            }
          }

          .swatch-option-not-available {
            background: linear-gradient(to top left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), #9d9d9d 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%), linear-gradient(to top right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) calc(50% - 0.8px), #9d9d9d 50%, rgba(0,0,0,0) calc(50% + 0.8px), rgba(0,0,0,0) 100%);
          }

          #gift-card-prices-select {
            padding: 10px;
            border-radius: 25px;
            width: 100px;
          }
        }
      }
    }

    #giftcard-add-to-cart-section {
      float: left;
      width: 50%;
      margin-bottom: 20px;
      padding: 0 5px;

      #giftcard-add-to-cart-parent {
        #giftcard-add-to-cart {
          margin-top: 20px;
          width: 100%;
          border-radius: 46px;
          padding: 7px;
          background-color: #bfdd00;
          border: none;
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 400;
          line-height: 2.2rem;
          position: relative;
          left: 50%;
          -webkit-transform: translate(-50%);
          transform: translate(-50%);
        }
      }
    }
    
    

    #product-add-to-cart-section {
      float: left;
      width: 100%;
      margin-bottom: 20px;
      padding: 0 5px;

      #product-add-to-cart-parent {
        float: left;
        width: 50%;

        #product-add-to-cart {
          width: 100%;
          border-radius: 46px;
          padding: 10px;
          background-color: $alt-lime;
          border: none;
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 400;
          line-height: 2.2rem;
        }
      }

      #product-qty-parent {
        #product-qty-control {
          float: right;
          margin-top: 6px;

          .qty-minus,
          .qty-plus {
            float: left;
            text-align: center;
          }

          .btn-qty {
            width: 40px;
            height: 40px;
            line-height: 40px;
            display: block;
            // border: 1px solid #dbdbdb;
            border: 1px solid $alt-lime;
            backface-visibility: hidden;
            text-decoration: none;
            border-radius: 34px;
            background: $alt-lime;

            &:hover {
              background: white;
              color: $alt-lime;
            }
          }

          .qty-input {
            float: left;

            #qty {
              border: none;
              background: 0 0;
              width: 38px;
              position: relative;
              left: 0;
              margin: 0 -1px;
              height: 40px;
              text-align: center;
            }
          }
        }
      }

      #product-gift-card-price {
        float: left;
        width: 50%;
        padding-left: 15px;

        .swatch-attribute {
          width: 50%;
          float: left;

          .swatch-attribute-label {
            font-size: 12px;
          }
          .swatch-attribute-selected-option {
            margin-left: 6px;
            font-size: 12px;
          }
          .swatch-attribute-options {
            float: left;
            width: 100%;
            margin-top: 7px;

            .swatch-option {
              height: 37px;
              width: 37px;
              float: left;
              position: relative;
              border-radius: 50px;
              border: 2px solid #dbdbdb;
              margin-right: 5px;
              text-align: center;
              line-height: 35px;
              font-size: 13px;

              &.selected {
                border: 2px solid #0298d8;
              }
            }

            #gift-card-prices-select {
              padding: 10px;
              border-radius: 25px;
              width: 100px;
              background: white;
            }
          }
        }
      }
    }

    #description-parent {
      clear: both;
      margin-bottom: 15px;
      padding: 0 5px;

      #description-title {
        font-size: 12px;
        font-weight: 700;
        margin-bottom: 8px;
      }
      #description {
        font-size: 12px;
      }
    }

    #product-extra-info {
      font-size: 12px;
      padding: 0 5px;

      .product-view-content-section {
        clear: both;

        .product-view-content-heading {
          color: $sacramento;
          font-size: 18px;
          font-weight: 600;
          text-transform: uppercase;
          border-top: 1px solid $sacramento;
          padding-top: 10px;
          padding-bottom: 10px;
          position: relative;

          .checkout-arrow {
            position: absolute;
            top: 6px;
            right: 0;
            background: $sacramento;
            height: 27px;
            width: 27px;
            border-radius: 30px;

            &::after {
              content: "\e688";
              font-family: Pe-icon-7-stroke;
              position: absolute;
              top: 2px;
              right: 1px;
              font-size: 25px;
              color: #fff;
            }
          }
        }

        .product-view-content {
          &#tab2-image-parent {
            text-align: center;

            img {
              max-height: 200px;
            }
          }

          .product-view-content-row {
            float: left;
            width: 100%;

            .product-view-content-left {
              float: left;
              width: 30%;

              img {
                padding: 15px;
                max-width: 100px;
                // margin-top: 20px;
                // margin-bottom: 20px;
              }
            }

            .product-view-content-right {
              float: left;
              width: 70%;

              p {
                margin-bottom: 1rem;
              }
            }
          }

          .product-view-content-faqs {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  #page-gift-card-details {
    #gift-card-inputs-form {
      .product-gift-card-details-container {
        .gift-card-details-product-info {
          float: left;
          width: 100%;
          margin-bottom: 7px;

          .gift-card-details-left-col {
            float: left;
            width: 60%;
            margin-top: 5px;

            .gift-card-details-product-name {
              font-size: 18px;
              margin-bottom: 10px;
              font-weight: bold;
            }

            .gift-card-details-product-price-container {
              margin-bottom: 10px;

              .gift-card-details-product-price-desc {
                display: inline;
                margin-right: 7px;
              }

              .gift-card-details-product-price {
                font-weight: bold;
                display: inline;
              }
            }
          }
          .gift-card-details-right-col {
            float: left;
            width: 40%;

            .gift-card-details-product-image {
              width: 175px;
              float: right;
              height: 109px;
              object-fit: cover;
              border-radius: 7px;
            }
          }
        }
        .gift-card-details-qty-info-container {
          clear: both;

          .gift-card-details-qty-info {
            .gift-card-details-qty {
              margin-bottom: 10px;
            }
          }
        }

        #gift-card-show-details {
          .show-details-row {
            display: flex;
            justify-content: space-between;
            margin: 5px 0;

            .show-details-desc {
            }
            .show-details-value {
            }
          }
          #show-details-message {
            margin: 5px 0;
            padding: 5px;
            border: 1px solid #022a1e;
          }
          #show-details-action-buttons {
            .show-details-button-parent {
              display: flex;
              justify-content: center;
              margin: 5px 0;

              .show-details-button {
                background: $alt-lime;
                border: none;
                border-radius: 30px;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                float: left;
              }
            }
            .show-details-or {
              margin: 5px 0;
            }
          }
        }
      }
      #gift-card-details-submit-button {
        background: $alt-lime;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        width: 100%;
      }
    }
  }

  #page-gift-card-recipient-details {
    #gift-card-recipient-inputs-form {
      .product-gift-card-details-container {
        .gift-card-details-product-info {
          float: left;
          width: 100%;
          margin-bottom: 23px;

          .gift-card-details-left-col {
            float: left;
            width: 60%;
            margin-top: 5px;

            .gift-card-details-product-name {
              font-size: 18px;
              margin-bottom: 10px;
              font-weight: bold;
            }

            .gift-card-details-product-price-container {
              margin-bottom: 10px;

              .gift-card-details-product-price-desc {
                display: inline;
                margin-right: 7px;
              }

              .gift-card-details-product-price {
                font-weight: bold;
                display: inline;
              }
            }
          }
          .gift-card-details-right-col {
            float: left;
            width: 40%;

            .gift-card-details-product-image {
              width: 175px;
              float: right;
              height: 109px;
              object-fit: cover;
              border-radius: 7px;
            }
          }
        }
        .gift-card-details-qty-info-container {
          clear: both;

          .gift-card-details-qty-info {
            .gift-card-details-qty {
              margin-bottom: 10px;
            }
          }
        }

        #gift-card-show-details {
          .show-details-row {
            display: flex;
            justify-content: space-between;
            margin: 10px 0;
            clear: both;

            .show-details-desc {
            }
            .show-details-value {
            }
          }
          #show-details-message {
            margin: 10px 0;
            padding: 10px;
            border: 1px solid $sea-glass;
            height: 100px;
            overflow-y: auto;
            border-radius: 7px;
          }
          #show-details-action-buttons {
            .show-details-button-parent {
              display: flex;
              justify-content: center;
              margin: 10px 0;

              .show-details-button {
                background: $alt-lime;
                border: none;
                border-radius: 30px;
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
                float: left;
              }
            }
            .show-details-or {
              margin: 5px 0;
              text-align: center;
            }
          }
        }
      }
      #gift-card-details-submit-button {
        background: $alt-lime;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        width: 100%;
      }
      .gift-card-details-button {
        background: $alt-lime;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        width: 40%;
        margin: 0 5%;
      }
    }
  }

  #page-gift-card-send-now-or-schedule {
    .product-gift-card-details-container {
      .gift-card-details-product-info {
        float: left;
        width: 100%;
        margin-bottom: 15px;

        .gift-card-details-left-col {
          float: left;
          width: 60%;
          margin-top: 5px;

          .gift-card-details-product-name {
            font-size: 18px;
            margin-bottom: 10px;
            font-weight: bold;
          }

          .gift-card-details-product-price-container {
            margin-bottom: 10px;

            .gift-card-details-product-price-desc {
              display: inline;
              margin-right: 7px;
            }

            .gift-card-details-product-price {
              font-weight: bold;
              display: inline;
            }
          }
        }
        .gift-card-details-right-col {
          float: left;
          width: 40%;

          .gift-card-details-product-image {
            width: 175px;
            float: right;
            height: 109px;
            object-fit: cover;
            border-radius: 7px;
          }
        }
      }

      #gift-card-show-details {
        .show-details-row {
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
          clear: both;

          .show-details-desc {
          }
          .show-details-value {
          }
        }
        #show-details-message {
          margin: 10px 0;
          padding: 5px 10px;
          border: 1px solid $sea-glass;
          height: 100px;
          overflow-y: auto;
          border-radius: 7px;
        }
        #show-details-action-buttons {
          margin: 15px 0;

          .show-details-button-parent {
            display: flex;
            justify-content: center;
            margin: 10px 0;

            .show-details-button {
              background: $alt-lime;
              border: none;
              border-radius: 30px;
              font-size: 16px;
              font-weight: 700;
              text-transform: uppercase;
              float: left;
              width: 170px;
            }
          }
          .show-details-or {
            margin: 5px 0;
            text-align: center;
          }
        }
      }
    }
  }

  #page-gift-card-schedule {
    .react-calendar {
      width: 100%;

      .react-calendar__tile--now {
        background: $lime;
      }

      button {
        color: unset;
        width: unset;
        padding: 6px !important;
      }

      .react-calendar__tile--active {
        background: $alt-lime;
      }
    }

    #schedule-time {
      margin: 10px 0;

      .time-col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        * {
          padding: 10px 0;
        }

        .time-colon {
          font-size: 28px;
        }

        .time-up-arrow {
          border-radius: 33px;
          width: 49px;
          display: flex;
          justify-content: center;

            &:hover {
              background: $alt-lime;
            }

            &:after {
              content: "\e682";
              font-family: Pe-icon-7-stroke;
              font-size: 28px;
              color: $sacramento; 
            }
        }
        .time-content {
          input {
            font-size: 28px;
            text-align: center;
            border: none;
          }
        }
        .time-down-arrow {
          border-radius: 33px;
          width: 49px;
          display: flex;
          justify-content: center;

          &:hover {
            background: $alt-lime;
          }

          &:after {
            content: "\e688";
            font-family: Pe-icon-7-stroke;
            font-size: 28px;
            color: $sacramento;
          }
        }
      }
    }

    #schedule-action-buttons {
      margin: 15px 0;

      .schedule-button-parent {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .schedule-button {
          background: $alt-lime;
          border: none;
          border-radius: 30px;
          font-size: 16px;
          font-weight: 700;
          text-transform: uppercase;
          float: left;
          width: 170px;
        }
      }
    }
  }

  #page-gift-card-mms-templates {
    .product-gift-card-mms-templates-container {
      #gift-card-mms-templates { 
        .gift-card-mms-template-parent {
          width: 100%;
          border: 1px solid #e8e8ff;
          border-radius: 13px;
          margin: 15px 0;

          .gift-card-mms-template {
            width: 100%;
            padding: 20px 20px 20px 20px;
            margin-bottom: 0;
          }

          .gift-card-mms-template-button-parent {
            text-align: center;

            .gift-card-mms-template-button {
              background-color: $alt-lime;
              border-radius: 30px;
              display: inline-block;
              font-weight: 700;
              width: 170px;
            }
          }
        }
      }
    }
  }

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.carousel-arrow-prev {
  left: 0px;
}

.carousel-arrow-next {
  right: 0px;
}


#page-gift-card-passes {
    .product-gift-card-passes-container {
      margin-top: 25px;

      #gift-card-passes { 
        .gift-card-pass-parent {
          width: 100%;
          border: 1px solid #e8e8ff;
          border-radius: 13px;
          margin: 15px 0;

          .gift-card-pass-button-parent {
            text-align: center;
            margin-top: 10px;

            .gift-card-pass-button {
              background-color: $alt-lime;
              border-radius: 50px;
              margin-bottom: 20px;
              display: inline-block;
              font-weight: 400;
              width: 120px;
            }
          }
        }
      }
    }
  } 
  
  #page-account-order {
    #account-order-items {
      .account-order-item {
        margin: 15px 0;
      }
    }
  }

  #page-account-address-information {
    #add-new-address-parent {
      #add-new-address-button {
        background-color: #bfdd00;
        display: inline;
        border-radius: 20px;
        color: $sacramento;
        width: unset;
        padding: 10px;
      }
    }
  }

  #account-orders {
    .account-order {
      margin: 20px 0;

      .account-order-increment-id {
        font-weight: bold;
      }
      .account-order-view-order {
        background: #bfdd00;
        width: unset;
        border-radius: 100px;
        padding: 10px 18px;
        font-weight: 400;
        margin-top: 5px;
      }
    }
  }

  #account-content {
    div {
      margin: 3px 0;
    }

    .bold-description {
      font-weight: bold;
    }

    // #account-all-shipping-addresses {
    //   .account-shipping-address {
    //     margin: 15px 0;
    //   }
    // }
  }

  .account-shipping-address {
    margin: 15px 0;
  }

  #page-account-saved-cards {
    #account-content {
      #account-saved-cards {
        .account-saved-card {
          float: left;
          width: 100%;
          margin: 6px 0 !important;

          .account-saved-card-brand {
            position: relative;
            float: left;
            width: 20%;
            text-align: center;

            .account-saved-card-brand-img {
              position: relative;
              height: 32px;
            }
          }
          .account-saved-card-number {
            position: relative;
            float: left;
            width: 40%;
            line-height: 32px;
            text-align: center;
          }
          .account-saved-card-expiry {
            position: relative;
            float: left;
            width: 40%;
            line-height: 32px;
          }
        }
      }
    }
  }

#offline-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 3000;

  #offline-modal {
    position: fixed;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 360px;
    z-index: 100;
    color: $sacramento;
    padding: 20px;
    top: 50%;
    padding-bottom: 5px;
    padding-top: 10px;

    #offline-heading {
      font-size: 24px;
      text-align: center;
      margin: 10px 20px;
    }
    #offline-text {
      margin-bottom: 20px;
    }
    #offline-button {
      background-color: $alt-lime;
      border-radius: 50px;
      margin-bottom: 20px;
      display: inline-block;
      font-weight: 400;
    }
  }
}

#product-out-of-stock-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 3000;

  #product-out-of-stock-popup {
    position: fixed;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    width: 90%;
    min-width: 90%;
    z-index: 100;
    color: $sacramento;
    padding: 20px;
    top: 50%;
    padding-bottom: 5px;
    padding-top: 10px;

    #product-out-of-stock-x {
      position: absolute;
      top: 1px;
      right: 11px;
      font-size: 24px;
    }

    #product-out-of-stock-heading {
      font-size: 24px;
      text-align: center;
      margin: 10px 20px;
    }

    #product-out-of-stock-text {
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

#unexpected-error-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 3000;

  #unexpected-error-modal {
    position: fixed;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    width: 90%;
    min-width: 90%;
    z-index: 100;
    color: $sacramento;
    padding: 20px;
    top: 50%;
    padding-bottom: 5px;
    padding-top: 10px;

    #unexpected-error-heading {
      font-size: 24px;
      text-align: center;
      margin: 10px 20px;
    }
    #unexpected-error-text {
      margin-bottom: 20px;
      text-align: center;
    }
  }
}

#default-address-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 3000;

  #default-address-modal {
    position: fixed;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%);
    width: 90%;
    min-width: 90%;
    z-index: 100;
    color: $sacramento;
    padding: 20px;
    top: 50%;
    padding-bottom: 5px;
    padding-top: 10px;

    
  }
}

#cart-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  z-index: 50;

  #cart-popup {
    position: fixed;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, 0);
    width: 90%;
    min-width: 90%;
    z-index: 100;
    color: $sacramento;
    padding: 20px;
    top: 70px;
    padding-bottom: 5px;
    padding-top: 10px;

    #cart-popup-x-parent {
      position: relative;

      #cart-popup-x {
        font-size: 23px;
        font-weight: 400;
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
      }
    }

    #loader-cart-parent {
      position: relative;

      #loader-cart {
        img {
          height: 130px;
          position: relative;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }
    }

    #cart-popup-heading {
      text-align: center;
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    #cart-products {
      max-height: 400px;
      overflow-y: auto;

      .cart-product {
        margin-bottom: 15px;
        float: left;
        width: 100%;
        border: 1px solid $smoke;
        border-radius: 7px;
        padding: 10px;

        .checkout-left-col {
          float: left;
          width: 55%;
          color: $sacramento;

          .checkout-bold-left {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 5px;
          }
          .checkout-normal {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 5px;
          }
          .checkout-bold {
            font-size: 16px;
            font-weight: 800;
            margin-bottom: 5px;
          }
          .checkout-qty {
            position: relative;
            text-transform: uppercase;
            margin-top: 4px;
            float: left;
            margin-bottom: 5px;

            .checkout-qty-number {
              border: 1px solid $sacramento;
              padding: 2px 7px;
              border-radius: 8px;
              font-size: 15px;
              font-weight: 700;
            }
          }

          .remove-product-parent {
            float: left;
            position: relative;
            top: 1px;

            .remove-product {
              border-radius: 39px;
              padding: 6px 10px;
              background: $warning;
              border: none;
              font-size: 11px;
              margin-left: 8px;
              width: 70px;
              font-weight: 400;
            }
          }
        }
        .checkout-right-col {
          float: left;
          width: 45%;
          position: relative;

          .checkout-image {
            width: 134px;
            float: right;
            margin-bottom: 0;
          }

          .checkout-giftcard-image {
            width: 150px;
            float: right;
            margin-bottom: 0;
            height: 93px;
            object-fit: cover;
            border-radius: 6px;
          }
        }
      }
    }

    #checkout-delivery-row {
      float: left;
      width: 100%;
      margin-bottom: 5px;

      .checkout-delivery-total {
        float: left;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .checkout-delivery-total-right {
        float: right;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }
    #checkout-total-row {
      float: left;
      width: 100%;
      margin-bottom: 10px;

      .checkout-delivery-total {
        float: left;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .checkout-delivery-total-right {
        float: right;
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
      }
    }

    #cart-buttons {
      width: 100%;
      height: 40px;
      clear: both;

      #cart-popup-checkout {
        background: $alt-lime;
        border: none;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        float: left;
        width: 40%;
      }

      #cart-popup-keep-shopping {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
        background: none;
        border: 1px solid $sacramento;
        border-radius: 45px;
        color: $sacramento;
        float: right;
        width: 58%;
        padding: 8px;
      }
    }

    #cart-popup-close-window-container {
      clear: both;
        margin-bottom: 10px;
        margin-top: 10px;
        display: flex;
        justify-items: center;

      #cart-popup-close-window-parent {
        display: flex;
        justify-content: space-evenly;
        width: 100%;

        #cart-popup-close-window {
          text-align: center;
          font-size: 11px;
          font-weight: 400;
          text-transform: uppercase;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    #cart-popup-empty {
      text-align: center;
      padding-bottom: 15px;
      font-size: 30px;
      font-weight: 300;
      padding-top: 10px;
    }
  }
}

.product-tile-parent {
  & > div {
    overflow-x: unset !important;
  }
  .product-tile {
    width: 100%;
    float: left;
    display: inline-block;
    padding: 17px;
    margin-bottom: 20px;
    box-sizing: border-box;
    max-width: 100%;
    background: #fff;
    border: 1px solid $smoke;

    .product-image-wrapper {
      margin-bottom: 12px;
      // height: 0;
      // display: block;
      // position: relative;
      // z-index: 1;
      // overflow: hidden;
      // padding-bottom: 100%;

      .product-image-photo {
        // display: block;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
        // height: auto;
        // max-width: 100%;
        // max-height: none;
        // height: auto !important;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .giftcard-image-wrapper {
      // height: 0;
      // display: block;
      // position: relative;
      // z-index: 1;
      // overflow: hidden;
      // padding-bottom: 106px;
      // border-radius: 5px;
      // margin-bottom: 10px;

      .product-image-photo {
        // display: block;
        // position: absolute;
        // top: 0;
        // bottom: 0;
        // left: 0;
        // right: 0;
        // margin: auto;
        // height: auto;
        // max-width: 100%;
        // max-height: none;
        // height: auto !important;
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    a {
      text-decoration: none;
    }
    .product-name {
      color: $sacramento;
      margin-top: 8px;
      margin-bottom: 11px;
      font-weight: 400;
      height: 40px;
      font-size: 20px;
    }

    .product-price {
      margin-bottom: 11px;
      font-weight: 400;
      // font-size: 14px;
    }

    .product-add-to-cart-parent {
      .product-add-to-cart {
        font-weight: 400;
        border-radius: 40px;
        background-color: $alt-lime;
        color: $sacramento;
        width: unset;
        padding: 10px 30px;
        // position: relative;
        // left: 50%;
        // transform: translate(-50%, 0);
      }
    }

    

    .product-out-of-stock {
      font-weight: 400;
      border-radius: 40px;
      background-color: white;
      color: $sacramento;
      border: 1px solid $sacramento;
      padding: 10px;
      width: 100%;
      font-size: 16px;
      text-align: center;
    }
  }
}

#feed {
  width: 100%;
  // margin-left: 15px;
  // margin-right: 15px;
  margin-top: 15px;
  float: left;

  #filter,
  #back {
    font-weight: 200;
    cursor: pointer;

    span {
      margin-left: 5px;
    }
  }
}

h2.h2 {
  font-size: 20px;
  font-weight: 200;
}

h1.h1 {
  font-size: 24px;
}

.card-validation {
  padding-bottom: 9px;
  font-size: 14px;
  color: #ff3636;
}

.form {
  margin-top: 15px;

  #forgot-password-parent {
    margin-top: 8px;
    text-align: center;

    a {
      color: $sacramento;
    }
  }

  .input-validation {
    padding: 5px;
    border-radius: 4px;
    padding-left: 9px;
    font-size: 14px;
    color: #ff3636;
  }

  .input-box {
    margin-bottom: 6px;

    input,
    select,
    #edit-comment,
    textarea {
      padding: 10px;
      border-radius: 5px;
      width: 100%;
      border: 1px solid #c7c7c7;
      font-size: 16px;
      font-weight: 400;
      font-family: "Nunito", sans-serif;
      margin-bottom: 5px;
      background: white;
    }

    textarea {
      resize: vertical;
    }
  }
}

#card-new-card-inputs.form {
  margin-top: 0;
}

button {
  background-color: $alt-lime;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  color: $sacramento;
  font-size: 16px;
  font-weight: 200;
  font-family: "Nunito", sans-serif;
  cursor: pointer;

  a {
    text-decoration: none;
  }
}

@media (min-width: $breakpoint-tablet) {
  .container {
    width: 700px;
  }

  #header-container {
    width: 700px;
    // left: 50%;
    // transform: translate(-50%, 0);
  }

  .form {
    // width: 400px;
    margin: auto;
  }

  #filter-channels {
    .form {
      width: 100%;
      float: left;
    }
  }
  #feed #filter-popup #filter-categories #category-selection {
    height: 192px;
  }

  .comment-comment {
    .form {
      width: 100%;
    }
  }

  .post-make-comment.page-bottom {
    width: 670px;
    left: 50% !important;
    transform: translate(-50%, 0px);
    z-index: 20;
  }

  .signup-popup-container {
    width: 400px !important;
  }
}

#loader-parent {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2000;

  #loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    img {
      height: 130px;
      position: relative;
      left: 50%;
      transform: translate(-50%, 0);
    }

    div {
      color: $alt-lime;
      font-size: 20px;
      text-align: center;
    }
  }
}

#terms-privacy {
  margin-bottom: 10px;
  font-size: 14px;
  float: left;
  width: 100%;

  #agreement-checkbox {
    float: left;
    width: 8%;
  }
  #agreement-text {
    float: left;
    width: 92%;
  }

  a {
    color: $sacramento;
  }
}

.background-white {
  background: white;

  #terms-content,
  #privacy-content {
    padding: 0 15px 15px;

    h1 {
      text-align: center;
    }

    li.animate.items_page_terms {
      margin-bottom: 13px;
      list-style: none;
      text-decoration: underline;
      position: relative;
      left: -38px;
    }
  }
}

.input-box {
  .select-wrapper {
    position: relative;

    &:after {
      content: "\f107";
      font: normal normal normal 20px/1 FontAwesome;
      right: 14px;
      top: 11px;
      position: absolute;
      pointer-events: none;
    }

    select {
      -webkit-appearance: none;
    }
  }
}

#my-account-nav {
  position: relative;
  top: 0;
  left: -15px;
  width: calc(100% + 30px);

  #my-account-nav-bar {
    background: #bfdd00;

    #my-account-nav-heading {
      font-size: 20px;
      padding: 10px 13px;
      color: $sacramento;
    }
    .my-account-arrow {
      position: absolute;
      top: 4px;
      right: 13px;

      &:after {
        content: "\e688";
        font-family: Pe-icon-7-stroke;
        font-size: 36px;
        color: $sacramento;
      }
    }
  }

  #my-account-nav-items {
    background: white;
    position: absolute;
    width: 100%;
    z-index: 100;

    .my-account-nav-item {
      padding: 10px 12px;
      border: 1px solid #d4d4d4;

      a {
        text-decoration: none;
        color: $sacramento;
      }
    }
  }
}

.grecaptcha-badge {
  display: none;
}

@supports (-webkit-touch-callout: none) {
  .input-box {
    .select-wrapper {
      &:after {
        top: 14px !important;
      }
    }
  }
}

@media (min-width: 768px) {
  #header,
  #header-container {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 400px;
  }

  .container {
    max-width: 400px;
  }

  #cart-popup {
    min-width: 380px !important;
    width: 380px !important;
  }

  #mobile-popup {
    min-width: 380px !important;
    width: 380px !important;

    .form {
      width: unset;
    }
  }

  #product-out-of-stock-popup {
    min-width: 380px !important;
    width: 380px !important;
  }

  #offline-modal {
    min-width: 380px !important;
    width: 380px !important;
  }

  #unexpected-error-modal {
    min-width: 380px !important;
    width: 380px !important;
  }

  #new-sm-menu {
    width: 400px;
    height: 600px;
  }
}

@media (max-width: 374px) {
  #registration-top-right {
    position: absolute !important;
    width: 50% !important;
    left: 50%;
    bottom: 10px;
    top: unset !important;
  }

  #registration-top-left {
    width: 50% !important;
  }
}

@media (max-width: 350px) {
  .container
    #page-checkout
    #checkout-cart
    .checkout-product
    a
    .checkout-right-col
    .checkout-image {
    width: 100%;
    padding-left: 10px;
  }

  .container #page-checkout #checkout-top #checkout-header-clock {
    width: 100px;
  }

  #cart-mask
    #cart-popup
    #cart-products
    .cart-product
    .checkout-right-col
    .checkout-image {
    width: 100%;
    padding-left: 10px;
  }
}

@media (max-width: 360px) {
  #cart-mask #cart-popup #cart-buttons #cart-popup-checkout,
  #cart-mask #cart-popup #cart-buttons #cart-popup-keep-shopping {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 319px) {
  #cart-mask
    #cart-popup
    #cart-products
    .cart-product
    .checkout-left-col
    .remove-product-parent {
    width: 100%;
    margin-top: 10px;
  }

  #cart-mask
    #cart-popup
    #cart-products
    .cart-product
    .checkout-left-col
    .checkout-qty {
    width: 100%;
  }

  #cart-mask
    #cart-popup
    #cart-products
    .cart-product
    .checkout-left-col
    .remove-product-parent
    .remove-product {
    margin-left: 0;
  }
}

@media (max-width: 334px) {
  .container #page-checkout #checkout-payment #card-new-card-inputs {
    padding-left: 0;
  }
}

@media (max-width: 510px) {
  .container
    #page-home
    #home-page-container
    #home-content
    #home-top-content
    #home-top-content-image {
    width: 100%;
  }
}

@media (max-width: 690px) {
  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-middle-content {
    width: 100%;
  }
  .container
    #page-home
    #home-page-container
    #home-content
    #home-top-content
    #home-top-content-image {
    width: 100%;
  }

  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-top-content
    #home-top-content-image {
    padding: 40px;
  }

  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-top-content {
    width: 80%;
  }
}

@media (max-width: 820px) {
  .container
    #page-home
    #home-page-container
    #home-form-section
    .container
    #home-form-content {
    width: 85%;
  }

  .container #page-home #home-page-container #home-form-section .container {
    width: 85%;
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */

  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-top-content {
    top: 7%;
  }

  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-middle-content {
    top: 42%;
  }

  .container
    #page-home
    #home-page-container
    #home-main-section
    #home-content
    #home-bottom-content {
    top: 67%;
  }
}

@media screen and (max-width: 991px) {
  #home-banner-left {
    -ms-grid-column: span 2 !important;
    grid-column-start: span 2 !important;
    -ms-grid-column-span: 2 !important;
    grid-column-end: span 2 !important;
    max-width: unset !important;
  }

  #home-banner-right {
    -ms-grid-column: span 2 !important;
    grid-column-start: span 2 !important;
    -ms-grid-column-span: 2 !important;
    grid-column-end: span 2 !important; 
  }

  #home-banner-heading-1 {
    position: static !important;
    text-align: center !important;
    width: unset !important;
    font-size: 40px !important;
  }

  #home-banner-heading-2 {
    margin-top: 40px !important;
    text-align: center;
    font-size: 32px !important;
  }

  #home-banner-text {
    text-align: center;
  }

  #home-banner-button-parent {
    text-align: center;
  }

  #home-banner-content-container {
    width: 100% !important;
  }

  #home-main-section {
    height: unset !important;
  }

  #home-form-section {
    height: 900px !important;
  }

  #home-banner-content {
    padding: 0 !important;
  }
}

@media screen and (max-width: 691px) {
  #home-banner-content-container {
    width: 100% !important;
    // margin-right: 3% !important;
    // margin-left: 3% !important;
    // padding-right: 5% !important;
    // padding-left: 5% !important;
  }

  #home-banner-content-container > div {
    padding-top: 35px !important;
  }
}

